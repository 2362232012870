import { getDataBydId, getPaginatedData, insertData, patchData } from './commonService';

export default class CompanyService {

    #defaultUrl = '/api/Company';
    #defaultResourceName = 'Firma';

    async getAllCompanies() {
        return await getPaginatedData(this.#defaultUrl + '/query', this.#defaultResourceName);
    }

    async searchCompanies(keyword) {
        return await getPaginatedData(this.#defaultUrl + '/query?CompanyName=' + keyword, this.#defaultResourceName);
    }


    async checkCompanyExists(taxNumber) {
        return await getPaginatedData(this.#defaultUrl + '/query?TaxNumber=' + taxNumber, this.#defaultResourceName);
    }

    async getCompanyById(companyId) {
        return await getDataBydId(this.#defaultUrl, companyId, this.#defaultResourceName);
    }

    async insertCompany(dataItem) {
        const createData = {
            "name": dataItem.name,
            "isActive": dataItem.isActive,
            "phone": dataItem.phone,
            "email": dataItem.email,
            "personName": dataItem.personName,
            "personPhone": dataItem.personPhone,
            "personEmail": dataItem.personEmail,
            "officialName": dataItem.officialName,
            "officialAddress": dataItem.officialAddress,
            "officialPostalCode": dataItem.officialPostalCode,
            "officialCityId": dataItem.officialCityId,
            "officialTownId": dataItem.officialTownId,
            "taxNumber": dataItem.taxNumber,
            "taxOffice": dataItem.taxOffice,
            "eInvoiceAddress": dataItem.eInvoiceAddress,
            "passiveReason": dataItem.passiveReason,
            "passiveDate": dataItem.passiveDate,
            "description": dataItem.description,
            "type": dataItem.type,
        }
        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }

    async updateCompany(companyId, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "name", "value": dataItem.name},
            {"op": "replace", "path": "isActive", "value": dataItem.isActive},
            {"op": "replace", "path": "phone", "value": dataItem.phone},
            {"op": "replace", "path": "email", "value": dataItem.email},
            {"op": "replace", "path": "personName", "value": dataItem.personName},
            {"op": "replace", "path": "personPhone", "value": dataItem.personPhone},
            {"op": "replace", "path": "personEmail", "value": dataItem.personEmail},
            {"op": "replace", "path": "officialName", "value": dataItem.officialName},
            {"op": "replace", "path": "officialAddress", "value": dataItem.officialAddress},
            {"op": "replace", "path": "officialPostalCode", "value": dataItem.officialPostalCode},
            {"op": "replace", "path": "officialCityId", "value": dataItem.officialCityId},
            {"op": "replace", "path": "officialTownId", "value": dataItem.officialTownId},
            {"op": "replace", "path": "taxNumber", "value": dataItem.taxNumber},
            {"op": "replace", "path": "taxOffice", "value": dataItem.taxOffice},
            {"op": "replace", "path": "eInvoiceAddress", "value": dataItem.eInvoiceAddress},
            {"op": "replace", "path": "passiveReason", "value": dataItem.passiveReason},
            {"op": "replace", "path": "description", "value": dataItem.description},
            {"op": "replace", "path": "type", "value": dataItem.type},
        ];

        if(dataItem.passiveDate != null && dataItem.passiveDate != '')
        {
            patchDocument.push(
                {"op": "replace", "path": "passiveDate", "value": dataItem.passiveDate.valueOf()}
            )
        }
        else {
            patchDocument.push(
                {"op": "replace", "path": "passiveDate", "value": null}
            )
        }

        return await this.patchCompany(companyId, patchDocument);
    }

    async patchCompany(id, patchDocument) {
        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);
    }

}